import { ReactNode } from "react";
import { CookieProvider } from "~/components/utils/useCookies";
import { NavShowingProvider } from "./NavShowingProvider";
import { NewsletterProvider } from "./NewsletterProvider";
import { PageTransitionProvider } from "./PageTransitionProvider";

export default function RootProvider({ children }: { children: ReactNode }) {
  return (
    <NavShowingProvider>
      <PageTransitionProvider>
        <NewsletterProvider>
          <CookieProvider>{children}</CookieProvider>
        </NewsletterProvider>
      </PageTransitionProvider>
    </NavShowingProvider>
  );
}
